import React, { useEffect, useState, useRef } from 'react';
import { App, Page, Button, Panel, Block, Navbar, Link, BlockHeader } from 'konsta/react';
import Draggable from 'react-draggable';
import './styles.css';

export default function ButtonsPage() {
  const [leftFloatingPanelOpened, setLeftFloatingPanelOpened] = useState(false);
  const [buttonsPanelOpened, setButtonsPanelOpened] = useState(false);
  const [inputsPanelOpened, setInputsPanelOpened] = useState(false);
  const [rightFloatingPanelOpened, setRightFloatingPanelOpened] = useState(false);
  const [elements, setElements] = useState([]);
  const [selectedElementIndex, setSelectedElementIndex] = useState(null);
  const [selectedElementSize, setSelectedElementSize] = useState({ width: 0, height: 0 });
  const elementRefs = useRef([]);
  const previewSectionRef = useRef(null);
  const isDragging = useRef(false);

  useEffect(() => {
    if (window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();

      tg.MainButton.text = "Publish Design";
      tg.MainButton.color = "#007BFF";
      tg.MainButton.show();

      tg.MainButton.onClick(() => {
        tg.sendData("Some data to send back to bot");
      });
    }
  }, []);

  const addElement = (element) => {
    setElements((prevElements) => [...prevElements, element]);
    setLeftFloatingPanelOpened(false);
    setButtonsPanelOpened(false);
    setInputsPanelOpened(false);
  };

  const handleMouseDown = () => {
    isDragging.current = false;
  };

  const handleMouseUp = (index) => {
    if (!isDragging.current) {
      setSelectedElementIndex(index);
      const elementRef = elementRefs.current[index];
      if (elementRef && elementRef.getBoundingClientRect) {
        const rect = elementRef.getBoundingClientRect();
        setSelectedElementSize({ width: rect.width, height: rect.height });
      }
    }
  };

  const handleDrag = () => {
    isDragging.current = true;
  };

  const handleClickOnContainer = (event) => {
    if (event.target === previewSectionRef.current) {
      setSelectedElementIndex(null);
      setRightFloatingPanelOpened(false);
    }
  };

  const handleOpenElementSettings = () => {
    if (selectedElementIndex !== null) {
      setRightFloatingPanelOpened(true);
    }
  };

  return (
    <App theme="ios">
      <Page>
        <div className="flex flex-col h-screen p-2">
          <div className="flex flex-col flex-grow justify-between rounded-2xl p-4 bg-customDark items-center">
            <div>Element control panel</div>
            <Button
              raised
              disabled={selectedElementIndex === null}
              onClick={handleOpenElementSettings}
            >
              Open element settings
            </Button>
          </div>
          <div className="flex flex-none mt-2 space-x-2 overflow-hidden">
            <div className="w-1/4 overflow-y-auto rounded-2xl p-4 bg-customDark h-custom-height flex flex-col items-center overflow-x-hidden">
              <Button outline large inline raised className='mb-1 mt-cus w-btn-width h-btn-height normal-case' onClick={() => setLeftFloatingPanelOpened(true)}>Blocks</Button>
              <Button outline large inline raised className='mb-1 w-btn-width h-btn-height normal-case' onClick={() => setButtonsPanelOpened(true)}>Buttons</Button>
              <Button outline large inline raised className='mb-1 w-btn-width h-btn-height normal-case' onClick={() => setInputsPanelOpened(true)}>Inputs</Button>
            </div>
            <div 
              ref={previewSectionRef} 
              className="w-3/4 rounded-2xl bg-black h-custom-height border-4 border-customDark p-0 relative" 
              id="preview-section"
              style={{ overflow: 'hidden', position: 'relative' }}
              onClick={handleClickOnContainer}
            >
              {elements.map((element, index) => (
                <Draggable
                  key={index}
                  grid={[5, 5]}
                  defaultPosition={{ x: 0, y: 0 }}
                  bounds="parent"
                  onStart={handleMouseDown}
                  onDrag={handleDrag}
                  onStop={() => handleMouseUp(index)}
                >
                  <div
                    ref={(el) => elementRefs.current[index] = el}
                    className={`cursor-pointer ${selectedElementIndex === index ? 'border-2 border-red-500 rounded-md' : ''}`}
                    style={{ width: 'fit-content', height: 'fit-content' }}
                  >
                    {element}
                  </div>
                </Draggable>
              ))}
            </div>
          </div>
        </div>

        <Panel
          side="left"
          floating
          opened={leftFloatingPanelOpened}
          onBackdropClick={() => setLeftFloatingPanelOpened(false)}
        >
          <Page className="no-safe-areas-top no-safe-areas-bottom">
            <Navbar
              title="Blocks"
              right={
                <Link navbar onClick={() => setLeftFloatingPanelOpened(false)}>
                  Close
                </Link>
              }
            />
            <BlockHeader className='text-tg'>Block options.</BlockHeader>
            <Block strong inset>
              <Button large raised outline onClick={() => addElement(
                <div style={{
                  width: '150px',
                  height: '100px',
                  backgroundColor: '#1c1c1e',
                  borderRadius: '10px'
                }}>
                </div>
              )}>Add Block</Button>
            </Block>
          </Page>
        </Panel>

        <Panel
          side="left"
          floating
          opened={buttonsPanelOpened}
          onBackdropClick={() => setButtonsPanelOpened(false)}
        >
          <Page className="no-safe-areas-top no-safe-areas-bottom">
            <Navbar
              title="Buttons"
              right={
                <Link navbar onClick={() => setButtonsPanelOpened(false)}>
                  Close
                </Link>
              }
            />
            <Block strong inset className="space-y-4">
              <p className='text-tg'>Large buttons</p>
              <Button large raised onClick={() => addElement(<Button large raised>Default</Button>)}>Default</Button>
              <Button large raised outline onClick={() => addElement(<Button large raised outline>Outlined</Button>)}>Outlined</Button>
              <Button large raised clear onClick={() => addElement(<Button large raised clear>Clear</Button>)}>Clear</Button>
              <Button large raised tonal onClick={() => addElement(<Button large raised tonal>Tonal</Button>)}>Tonal</Button>
            </Block>
            <Block strong inset className="space-y-4">
              <p className='text-tg'>Small buttons</p>
              <Button raised onClick={() => addElement(<Button raised>Default</Button>)}>Default</Button>
              <Button raised outline onClick={() => addElement(<Button raised outline>Outlined</Button>)}>Outlined</Button>
              <Button raised clear onClick={() => addElement(<Button raised clear>Clear</Button>)}>Clear</Button>
              <Button raised tonal onClick={() => addElement(<Button raised tonal>Tonal</Button>)}>Tonal</Button>
            </Block>
          </Page>
        </Panel>

        {/* Панель справа */}
        <Panel
          side="right"
          floating
          opened={rightFloatingPanelOpened}
          onBackdropClick={() => setRightFloatingPanelOpened(false)}
        >
          <Page className="no-safe-areas-top no-safe-areas-bottom">
            <Navbar
              title="Element Settings"
              right={
                <Link navbar onClick={() => setRightFloatingPanelOpened(false)}>
                  Close
                </Link>
              }
            />
            <Block strong inset>
              {selectedElementIndex !== null && (
                <div style={{ width: selectedElementSize.width, height: selectedElementSize.height }}>
                  {elements[selectedElementIndex]}
                </div>
              )}
            </Block>
          </Page>
        </Panel>
      </Page>
    </App>
  );
}
